.left-logo {
  height: 60px;
}

.container-fluid {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.right-logo-container {
  position: absolute;
  right: 150px;
  top: -15px;
}

.right-logo {
  height: 180px;
  z-index: 10;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;
  justify-content: end;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
}

.nav-link {
  color: black;
  text-transform: uppercase;
  font-size: 14px !important;
  font-family: 'Noto Sans', sans-serif;
}

.dropdown-toggle {
  outline: none;
  box-shadow: none;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  background-color: transparent;
  color: white;
}

.dropdown-toggle:focus,
.dropdown-toggle:active {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu {
  background-color: #454444;
  border: none;
  font-family: 'Noto Sans', sans-serif;
}

.dropdown-item {
  padding: 5px 10px;
}


.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #c8102e !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #fff !important;
  background-color: #c8102e !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff !important;
  background-color: #c8102e !important;
}

.navbar {
  background-color: white;
  height: 80px;

  margin: 0 auto;
  padding: 1rem 0;
}

.language-selector {
  border-radius: 4px;
  outline: none;
  width: 120px;
  font-size: 14px;
  background-color: #fff;
}

.form-select {
  color: white !important;
  background-color: transparent !important;
  outline-color: black;
  filter: invert(0.6);
  font-weight: normal !important;
  font-size: 14px !important;
  border: none !important;
  outline: 1px solid black !important;
}

.form-select option {
  background-color: white !important;
  color: black !important;
}

.language-selector {
  width: 150px;
  min-width: 120px;
  max-width: 150px;
  font-family: 'Noto Sans', sans-serif;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}


@media only screen and (max-width: 992px) {
  
  .form-select {
    color: black !important;
    background-color: transparent !important;
    filter: invert(1);
    border: none !important;
    font-weight: normal !important;
    outline: 1px solid black !important;
  }

  .navbar-divider {
    width: 80%;
    border: none;
    border-top: 1px solid white;
    margin: 10px auto;
  }

  .navbar > .container-fluid {
    padding-right: 20px !important;
  }

  .navbar {
    background-color: #B21A29;
    height: 100px;
    
  }

  .navbar-brand {
    margin-right: 0;
  }

  .left-logo {
    position: relative;
    padding-left: 10px;
    content: url('/src/img/hyperleague blanco@4x.png'); 
  }

  .navbar-light .navbar-nav .nav-link {
    color: white !important;
    font-size: 18px !important;
    font-family: 'Noto Sans', sans-serif;
  }

  .right-logo {
    display: none;
  }

  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .nav-toggle > .bar{
    position: relative;
    width: 32px;
    height: 2px;
    background-color: white;
  }
  .nav-toggle > .bar::before, .nav-toggle > .bar::after{
    content: "";
    position: absolute;
    height: 2px;
    background: white;
    border-radius: 2px;
    transition: all .45s ease-in-out;
  }

  .nav-toggle > .bar::before{
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after{
    width: 32px;
    transform: translateY(8px);
  }
  .nav-toggle.open > .bar{
    transform: translateX(-40px);
    background: transparent;
  }
  .nav-toggle.open > .bar::before{
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after{
    transform: rotate(-45deg) translate(26px, 26px);
  }
  
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  
  .navbar-toggler.open .navbar-toggler-icon {
    transform: rotate(45deg);
  }
  
  .navbar-toggler.open .navbar-toggler-icon::before {
    transform: rotate(45deg) translateY(8px);
  }
  
  .navbar-toggler.open .navbar-toggler-icon::after {
    transform: rotate(-45deg) translateY(-8px);
  }
  
  .navbar-collapse {
    position: fixed;
    top: 90px;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 0;
    background-color: #B21A29;
    overflow: hidden;
    transition: max-height 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 999;
  }
  
  .navbar-collapse.show {
    max-height: 100%;
  }

  .dropdown-menu {
    --bs-dropdown-bg: transparent !important;
    --bs-dropdown-link-color: white !important;
    --bs-dropdown-border-width: none !important;
    --bs-dropdown-min-width: none !important;
      text-align: center !important;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-items: center;
  }

  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
    border: none;
    background: none;
  }

  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #F8AF2F !important;
  }
  
  .dropdown-item:focus, .dropdown-item:hover {
      color: #F8AF2F !important;
      background-color: #0000 !important;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #F8AF2F !important;
    background-color: #0000 !important;
  }
  
}