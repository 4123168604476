.tab-navigation {
    display: flex;
    position: relative;
}

.tab-button {
    flex: 1;
    padding: 10px 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    transition: color 0.3s;
    text-align: center;
    font-weight: bold;
}

.tab-button.active {
    color: #c8102e;
}

.tab-button.disabled {
    color: #b0b0b0;
    cursor: not-allowed;
}

.tab-button.active::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 2px;
    width: 80%;
    background-color: #c8102e;
    transition: width 0.3s;
}

.tab-content {
    padding: 20px;
    background-color: white;
}

.hero-league-section {
    background-color: #B21A29;
    height: 200px;
    overflow: hidden;
}

.hero-league-content {
    display: flex;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.hero-league-image {
    flex: 1;
    height: 100%;
    overflow: hidden;
}

.hero-league-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-league-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    color: white;
}

.hero-league-text h1 {
    font-size: 2.5em;
}

.hero-league-text p {
    margin: 0px !important;
    font-size: 1em;
    line-height: 1.4;
    color: white !important;
}

.calendar-image,
.league-image {
    display: block;
    margin: 0 auto;
    max-width: 100%; 
    height: auto;
}

.league-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.teams-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 20px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-items: center;
}

.team-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 140px;
}

.team-icon-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.teams-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100px;
    max-height: 100px;
}

.team-label {
    margin-top: 5px;
    font-size: 14px;
    color: #171717;
    padding: 4px 30px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.section-title {
    margin-bottom: 40px;
    margin-top: 60px;
    text-align: left;
}

.section-title:first-child {
    margin-top: 0;
}

.spacing-unified {
    margin-top: 30px;
    margin-bottom: 10px;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 50px;
    justify-items: center;
    align-items: center;
    margin-bottom: 70px;
}

.calendar-image {
    max-width: 100%;
    height: auto;
    display: block;
}

.centered-text {
    font-weight: normal;
}

@media (max-width: 768px) {
    .calendar-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 0px !important;
    }

    .calendar-image {
        margin-bottom: 30px;
    }

    .hero-league-section {
        position: relative;
        height: 200px;
    }
    
    .hero-league-content {
        position: relative;
        z-index: 2;
    }
    
    .hero-league-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    
    .hero-league-image img {
        height: auto !important;
        position: relative;
    }
    
    .hero-league-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #B21A29;
        padding: 1rem;
        color: white !important;
        z-index: 2;
        text-align: center;
    }
    
    .section-title {
        margin-top: 50px;
        margin-bottom: 30px;
        text-align: center;
    }
    
    .spacing-unified {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .teams-container {
        grid-template-columns: repeat(4, minmax(70px, 1fr));
        gap: 15px;
        margin-bottom: 30px;
    }
    
    .team-item {
        max-width: 70px;
    }
    
    .team-icon-wrapper {
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
    }
    
    .teams-icon {
        max-width: 60px;
        max-height: 60px;
    }
    
    .team-label {
        font-size: 12px;
        padding: 4px 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .centered-text {
        text-align: center;
        margin: 0 auto;
    }

    .league-image {
        margin-bottom: 50px;
    }

    .tab-button{
        color: black !important;
    }

    .tab-button.disabled {
        color: #b0b0b0 !important;
        cursor: not-allowed !important;
        opacity: 1 !important;
        -webkit-text-fill-color: #b0b0b0 !important;
        pointer-events: none !important;
    }

    .tab-button:active {
        outline: none !important;
        background-color: white !important;
        color: #B21A29 !important;
    }

    .tab-button.active {
        color: #B21A29 !important;
    }

    .tab-button:focus {
        color: #B21A29 !important;
        background-color: transparent !important;
        outline: none !important;
    }
    
    .tab-button:focus-visible {
        color: black !important;
        background-color: transparent !important;
        outline: none !important;
    }

    .nav-tabs .nav-link {
        color: black !important; 
        text-decoration: none !important
    }
    
    .nav-tabs .nav-link.active {
        color: red !important;
    }
}
