p {
  font-size: 16px !important;
  color: black !important;
  margin: 10px 0 !important;
  padding: 5px;
  line-height: 1.5 !important;
  font-family: 'Noto Sans', sans-serif !important;
}

h1, h2, h3 {
  font-family: 'Oswald', sans-serif !important;
  font-weight: bold !important;
}

.special-font {
  font-family: 'Oswald', sans-serif !important;
  padding: 5px !important;
  line-height: 1.5 !important;
  margin: 10px 0 !important;
  font-size: 21px !important;
}

.home-container {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.main-content {
  background-color: #F5F4F4;
}

.home-content {
  background-color: white;
  padding: 50px;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 0;
}

.hero-section {
  background-color: #B21A29;
  /* background-image: url('/src/img/Banner\ Patrocinadores.png'); */
  color: white;
  padding: 4rem 0;
  position: relative
}

.hero-section .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.video-container iframe {
  position: relative;
  z-index: 2;
}

.star {
  position: absolute;
  top: -70px;
  right: -70px;
  width: 145px;
  height: 145px;
  background-color: white;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  z-index: 1;
  transform: rotate(-21deg);
}

.hero-text {
  flex: 1;
  padding-left: 2rem;
}

.hero-text p {
  color: white !important;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.leagues-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.league-card {
  background-color: #B21A29;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  height: 170px;
  align-items:flex-end;
}

.league-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.league-card .text-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.card-content {
  padding: 1rem;
}

.league-card h3 {
  color: white;
  margin: 0;
}

.league-card p {
  color: white !important;
  margin: 0;
}

.hyper-league-logo {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: auto;
  position: relative;
}

.leagues-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 30px
}

.logo-image {
  position: fixed;
  right: 4%;
  bottom: calc(24% - 170px);
  width: 140px;
  z-index: 10;
}

.clubs-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.clubs-bar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.club-icon {
  width: 50px;
  height: auto;
  margin: 10px;
}

.list-home {
  font-size: 24px;
  font-weight: bold;
}

.list2-home li{
  margin-top: 0px !important;
}

.list-home p, .list2-home li{
  font-size: 16px !important;
  font-weight: 400 !important;
}

.list-home li{
  margin-top: 24px;
}
.list-home h3{
  font-size: 24px;;
}

.banner-home{
  width: -webkit-fill-available;
  width: 100% !important;
}


@media (max-width: 968px) {

  .banner-home{
    max-width: 400px;
  }
  
  .clubs-bar {
    display: none;
  }

  .content-wrapper {
    max-width: 100%;
    padding: 2rem 1rem;
  }

  .home-content{
    padding: 0;
  }
  
  .video-container iframe {
    max-width: 100%;
    height: 300px;
  }
  

  .leagues-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 0px;
  }

  .league-card {
    position: relative;
    overflow: hidden;
    height: 200px;
  }

  .league-card h3 {
    font-size: 34px;
  }

  .league-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .card-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #B21A29;
    padding: 1rem;
    color: white !important;
    z-index: 2;
  }

  .card-content h3, 
  .card-content p {
    margin: 0;
    text-align: left;
    color: white !important;
  }

  .hero-section .content-wrapper {
    flex-direction: column;
  }

  .hero-text {
    padding-left: 0;
    padding-top: 1rem;
  }


  .logo-image {
    position: fixed;
    right: 4%;
    bottom: calc(10% - 58px);
    width: 100px;
    z-index: 10;
  }

  .hero-section {
    padding: 0px;
  }

  .star {
    position: absolute;
    top: -40px;
    right: -30px;
    width: 80px;
    height: 80px;
    background-color: white;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    z-index: 1;
    transform: rotate(-21deg);
  }
}
