.footer {
  background-color: #a4161a;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left h1 {
  font-family: 'Noto Sans', sans-serif !important;
  font-size: 24px;
  margin: 0;
}

.footer-center p {
  font-size: 12px;
  margin: 0;
  color: white !important;
}

.footer-right {
  display: flex;
  gap: 10px;
}

.icon {
  color: white;
  cursor: pointer;
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.1);
}

@media (max-width: 968px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    margin-bottom: 20px;
  }
  .icons{
    width: 32px;
    height: 32px;
  }
}
